




















import Vue from 'vue'
import Component from 'vue-class-component'
import globalMixins from '@/mixins/global.ts'

@Component({
  mixins: [globalMixins]
})
export default class PersonalPassword extends Vue<globalMixins> {
  PassForm = {
    originPass: '',
    pass: '',
    checkPass: ''
  }
  changing = false
  ruleObj = {
    required: true,
    max: 20,
    min: 6,
    message: '密码是必须的，长度为6-20位！',
    trigger: 'blur'
  }
  rule = {
    originPass: [
      this.ruleObj
    ],
    pass: [
      this.ruleObj
    ],
    checkPass: [
      this.ruleObj
    ]
  }
  submit () {
    let PassForm = this.$refs.PassForm as HTMLFormElement
    PassForm.validate((valid: boolean) => {
      if (this.PassForm.originPass === this.PassForm.pass) {
        this.message('修改密码失败，原始密码不能跟新密码一致！')
      } else if (this.PassForm.pass != this.PassForm.checkPass) {
        this.message('两次验证密码不一致！')
      } else if (!valid) {
        this.message('修改密码失败！')
      } else {
        this.changing = true
        let params = {
          original_password: this.PassForm.originPass,
          new_password: this.PassForm.pass
        }
        let info = JSON.stringify(params)
        this.$emit('updatePassword', info)
      }
    })
  }
  message (msg: string) {
    this.errorMsg(msg)
  }
  reset () {
    (<HTMLFormElement>this.$refs.PassForm).resetFields()
  }
}
