




import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import personalPassword from '@/components/personal/password.vue'
import globalMixins from '@/mixins/global.ts'

@Component({
  components: {
    personalPassword
  },
  mixins: [globalMixins]
})
export default class Password extends Vue<globalMixins> {
  @Action('updatePassword') updatePassword: Function
  updatePwd (info) {
    let params = JSON.parse(info)
    this.updatePassword({ params }).then(async (res) => {
      if (res.success === true) {
        await this.successMsg('修改密码成功！')
        await this.$router.push('/')
      } else {
        this.errorMsg('修改密码失败！')
      }
    }).catch((error: any) => {
      this.errorMsg('修改密码失败！')
    })
  }
}
